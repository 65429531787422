import React from 'react'
import Link from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'

import PageHero from '../components/pageHero'
import Blockquote from '../components/sections/blockquote'
import ContentCenter from '../components/sections/contentCenter'

const Maintenance = () => (
	<Layout>
		<Seo title='Техническое обслуживание сайтов' />
		<PageHero
			title='Обслуживание сайтов'
			description='Отправь заявку и мы возьмем заботу о технической стороне твоего сайта на себя, а ты сможешь сосредоточиться на задачах по его развитию.'
			button='Отправить заявку'
			link='https://forms.gle/65T4KN4BtX4omHmC9'
			image='/images/raccoon-006-1920.jpg'
		/>
		<Blockquote
			content='На этой странице мы рассказываем о технической стороне владения сайтом, о том что нужно делать, чтобы он работал без перебоев и был хорошо защищен.'
		/>
		<ContentCenter
			subtitle='Надежный сайт'
			title='Что мы делаем чтобы твой сайт работал четко'
			intro='Здесь интро, которое мы напишем позже...'
		>
			<p>Постепенно мы здесь всё опишем и ты будешь знать прямо всё-всё, а пока просто заказывай ;)</p>
		</ContentCenter>
	</Layout>
)

export default Maintenance