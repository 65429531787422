import React from 'react'

  // Example:
  // {phone !== '' ? (
  //   <p>{phone}</p>
  // ) : null}

function pageHero({ title, description, button, link, image }) {

  return (
    <div className="relative bg-indigo-800">
      <div className="absolute inset-0">
        <img
          className="w-full h-full object-cover"
          src={image}
          alt={title}
        />
        <div className="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">{title}</h1>
        <p className="mt-6 text-xl text-indigo-100 max-w-3xl">
          {description}
        </p>

        {button !== '' ? (
          <div className="mt-10 max-w-sm sm:max-w-none sm:flex sm:justify-left">
            <div className="space-y-4 sm:space-y-0 sm:inline-grid sm:grid-cols-2 sm:gap-5">
              <a
                href={link}
                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8"
                target='_blank'
              >
                {button}
              </a>
              {/* <a
                href="#"
                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
              >
                Live demo
              </a> */}
            </div>
          </div>
        ): null}

      </div>
    </div>
  )

}

pageHero.defaultProps = {
  title: 'Заголовок страницы',
  description: 'Здесь должен находится какой-то полезный текст, но кто-то, похоже, забыл написать и разместить его. Придется разобраться что к чему и наказать кого-попало.',
  button: 'Кнопка',
  link: '#',
  image: '/images/default-hero-page-1920.jpg',
  // image: 'https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100',
  // image: 'default-hero-page.jpg',
}

export default pageHero
