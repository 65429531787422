import React from 'react'

/* This example requires Tailwind CSS v2.0+ */
function Blockquote( {content} ) {
  return (
    <section className="py-12 bg-black-50 overflow-hidden md:py-20 lg:py-24">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="svg-workcation"
        >
          <title id="svg-workcation">EnotWeb</title>
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={404} fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
        </svg>

        <div className="relative">
          <blockquote className="mt-10">
            <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
              <p>
                &ldquo; {content} &rdquo;
              </p>
            </div>
          </blockquote>
        </div>
      </div>
    </section>
  )
}

Blockquote.defaultProps = {
  content: 'Есть мнение, что сделать сайт трудно и дорого. Это верно для больших проектов. А если нужен лэндинг, визитка или маленький интернет-магазин, то решение есть - готовые сайты на базе системы WordPress. Это быстро и доступно.'
}

export default Blockquote